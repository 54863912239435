import { Footer } from 'flowbite-react';
import { Outlet } from "react-router-dom";

export default function DefaultLayout() {

  return (
    <div className="min-h-screen flex flex-col w-full">
      <main className="mx-1 md:mx-20 h-full transition-all bg-lightPrimary relative flex flex-grow">
        <div className="flex-1">
          <Outlet />
        </div>
      </main>

      <Footer container className="mt-4 sticky bottom-0 z-40">
        <Footer.Copyright href="#" by="Licitas" year={2024} />
        <Footer.LinkGroup>
          {/* Use as={Link} feature for Footer.Links after Flowbite fix for this issue is merged - check here https://github.com/themesberg/flowbite-react/pull/1244 */}
          <Footer.Link href="#">About</Footer.Link>
          <Footer.Link href="#">Privacy Policy</Footer.Link>
          <Footer.Link href="#">Licensing</Footer.Link>
          <Footer.Link href="#">Contact</Footer.Link>
        </Footer.LinkGroup>
      </Footer>
    </div>
  );
}
