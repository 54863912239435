import { Category } from "api/models/category";
import { Country } from "api/models/country";
import Drawer from "components/drawer";
import { Accordion, Button, Dropdown } from "flowbite-react";
import useAuctionsSearchParams from "hooks/auctionsSearchParams";
import { useEffect, useState } from "react";
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";
import AccordionFilterTitle from "./AccordionFilterTitle";
import CheckboxListFilter from "./CheckboxListFilter";
import SearchInputFilter from "./SearchInputFilter";
import SelectedFiltersBanner from "./SelectedFiltersBanner";

const AuctionsFilters = (props: {
  withCategories: Category[],
  withCountries: Country[]
}) => {
  const { withCategories: categories, withCountries: countries } = props;
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [showFiltersDrawer, setShowFiltersDrawer] = useState(false);
  const [countrySearchText, setCountrySearchText] = useState("");
  const [filteredCountries, setFilteredCountries] = useState<Country[]>(countries);
  const { params: auctionsSearchParams, setCountry, setCategory, setPage } = useAuctionsSearchParams();
  const { country: selectedCountries, category: selectedCategories } = auctionsSearchParams;

  useEffect(() => {
    const handleResize = (() => window.innerWidth < 1200 ? setIsSmallScreen(true) : setIsSmallScreen(false));
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const onChangeCountrySearchText = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setCountrySearchText(event.currentTarget.value);
    filterCountries(event.currentTarget.value);
  }

  const filterCountries = (text: string) => {
    if (!text)
      setFilteredCountries(countries);
    setFilteredCountries(countries.filter(c => c.name.toLowerCase().includes(text.toLowerCase())));
  }

  const handleCountryKeyDownSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.stopPropagation();
    if (event.key === 'Enter') {
      setCountrySearchText(event.currentTarget.value);
      filterCountries(event.currentTarget.value);
    } else if (event.key === 'Escape') {
      setCountrySearchText("");
      filterCountries("");
    }
  }

  const onSelectCountry = (country: string) => {
    let newCountries = [];
    if (selectedCountries.includes(country)) {
      newCountries = selectedCountries.filter(c => c !== country);
    }
    else {
      newCountries = [...selectedCountries, country];
    }
    setCountry(newCountries);
    setCountrySearchText("");
    filterCountries("");
  }

  const onSelectCategory = (category: Category) => {
    let newCategories = [];
    if (selectedCategories.includes(category.id)) {
      newCategories = selectedCategories.filter(c => c !== category.id);
    }
    else {
      newCategories = [...selectedCategories, category.id];
    }
    setCategory(newCategories);
  }

  const displaySelectedFilters = (selectedFilters: string[]) => {
    if (selectedFilters && selectedFilters.length > 0) {
      let displayed = selectedFilters[0] ?? "";
      if (selectedFilters.length > 1) {
        displayed += ` +${selectedFilters.length - 1}`;
      }
      return displayed;
    }
  }

  const onResetAllFilters = () => {
    setCategory();
    setCountry();
    setPage();
  }

  const displaySelectedCategories =
    displaySelectedFilters(selectedCategories.map(cat => categories.find(c => c.id === cat).name));

  const displayedSelectedCountries =
    displaySelectedFilters(selectedCountries.map(country => countries.find(c => c.code === country).name));

  const categoriesFilterContent =
    categories && <CheckboxListFilter label="categories" items={categories} onSelectItem={onSelectCategory} isChecked={(item) => selectedCategories.includes(item.id)} onResetClick={setCategory} />;

  const countriesFilterContent =
    countries && <>
      <SearchInputFilter label="countries" searchValue={countrySearchText} searchPlaceholder="Caută țară..." onChangeSearch={onChangeCountrySearchText} onKeyDownSearch={handleCountryKeyDownSearch} />
      <CheckboxListFilter label="countries" items={filteredCountries.map((c) => ({ id: c.code, name: c.name }))} onSelectItem={(item) => onSelectCountry(item.id)} isChecked={(item) => selectedCountries.includes(item.id)} onResetClick={setCountry} />
    </>;

  return (
    <>
      <Drawer isOpen={showFiltersDrawer && isSmallScreen} onClose={() => setShowFiltersDrawer(false)}>
        <div className="mt-2 space-y-4">
          <Accordion collapseAll flush>
            {categories ? <Accordion.Panel>
              <Accordion.Title>
                <AccordionFilterTitle titleLabel="Categorie" selectedItems={displaySelectedCategories} onClearFilter={setCategory} />
              </Accordion.Title>
              <Accordion.Content> {categoriesFilterContent}</Accordion.Content>
            </Accordion.Panel> : <></>}

            {countries ? <Accordion.Panel>
              <Accordion.Title>
                <AccordionFilterTitle titleLabel="Locație" selectedItems={displayedSelectedCountries} onClearFilter={setCountry} />
              </Accordion.Title>
              <Accordion.Content> {countriesFilterContent}</Accordion.Content>
            </Accordion.Panel> : <></>}
          </Accordion>

          <div className="space-y-2">
            <Button outline color="light"
              onClick={onResetAllFilters}
              className="w-full text-white rounded-lg">
              Șterge filtre
            </Button>
            <Button color="brand"
              onClick={() => setShowFiltersDrawer(false)}
              className="w-full text-white rounded-lg">
              Vezi licitații
            </Button>
          </div>
        </div>
      </Drawer>

      {isSmallScreen &&
        <Button size="sm" outline color="light"
          onClick={() => setShowFiltersDrawer(true)}
          className="w-full text-white rounded-lg">
          <HiOutlineAdjustmentsHorizontal className="mr-2 h-5 w-5" />Filtre
        </Button>}

      <div className="flex flex-row items-center justify-start gap-5">

        {!isSmallScreen &&
          <div className="basis-4/6 flex justify-start items-center gap-4">
            {categories &&
              <Dropdown label="Categorie" color="light" dismissOnClick={false} className="!min-w-44">
                {categoriesFilterContent}
              </Dropdown>}

            {countries &&
              <Dropdown label="Locație" color="light" dismissOnClick={false} className="!min-w-44">
                {countriesFilterContent}
              </Dropdown>}

            <span onClick={onResetAllFilters}
              className="cursor-pointer text-sm text-brand-600 font-semibold hover:text-brand-800">
              Șterge filtre
            </span>
          </div>}
      </div>

      <div className="flex flex-row gap-4">

        {!isSmallScreen && <>
          {selectedCategories.length > 0 &&
            <SelectedFiltersBanner order={2} displayedItems={`Categorie: ${displaySelectedCategories}`} onResetClick={setCategory} />}

          {selectedCountries.length > 0 &&
            <SelectedFiltersBanner order={3} displayedItems={`Locație: ${displayedSelectedCountries}`} onResetClick={setCountry} />}
        </>}
      </div>
    </>
  );

};

export default AuctionsFilters;