import useAuctionsHub from "api/hubs/auctionsHub";
import { AuctionSummary } from "api/models/auctionSummary";
import AuctionSummaryCard from "components/card/AuctionSummaryCard";
import { Timeline } from "flowbite-react";
import { neatDateWithWeekdayWithoutTime } from "formatters/date";
import { useState } from "react";
import { HiCalendar } from "react-icons/hi";
import { groupBy } from "types/group-by";

const AuctionsListView = ({ auctionSummaries }: { auctionSummaries: AuctionSummary[] }) => {
  const [auctions, setAuctions] = useState(auctionSummaries);
  useAuctionsHub(auctions.map(auction => auction.id),
    (notification) => {
      setAuctions(prevData => {
        return prevData.map(auction => {
          if (auction.id !== notification.auctionId) {
            return auction;
          }

          return {
            ...auction,
            status: notification.status
          }
        });
      });
    }
  );

  const groupedAuctions = groupBy(auctions, (auction: AuctionSummary) => neatDateWithWeekdayWithoutTime(auction.endDateUtc));

  return <Timeline className="ml-4 lg:ml-0">
    {Object.keys(groupedAuctions).map((endDate, index) => (
      <Timeline.Item key={index} className="!mb-6 md:!mb-10 !ml-2 md:!ml-6">
        <Timeline.Point icon={HiCalendar} />
        <Timeline.Content>
          <Timeline.Time className="ml-4 md:ml-0 font-semibold text-gray-700">{endDate}</Timeline.Time>
          <Timeline.Body className="flex flex-col space-y-4 mt-4">
            {groupedAuctions[endDate].map((auction) => (
              <AuctionSummaryCard key={auction.id} auction={auction} />
            ))}
          </Timeline.Body>

        </Timeline.Content>
      </Timeline.Item>
    ))}
  </Timeline>;
};

export default AuctionsListView;
