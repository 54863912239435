import { neatDate } from "formatters/date";
import moment from "moment";
import Countdown from "react-countdown";

function LotEndDate({
  endDate,
  showLabel,
  dateClass,
  labelClass,
  className
}: Readonly<{
  endDate: Date,
  showLabel?: boolean
  dateClass?: string,
  labelClass?: string,
  className?: string
}>) {
  const remainingTime = moment.duration(moment(endDate).diff(new Date()));
  return (
    remainingTime.asHours() < 24
      ? <p className={className}>{showLabel && <span className={labelClass}>Se încheie în </span>} <Countdown date={endDate} renderer={({ hours, minutes, seconds }) => <span className={dateClass}>{hours}h {minutes}m {seconds}s</span>}></Countdown></p>
      : <p className={className}>{showLabel && <span className={labelClass}>Se încheie la </span>} <span className={dateClass}>{neatDate(endDate)}</span></p>
  )
}
export default LotEndDate;
