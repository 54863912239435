import { AuctionSummary } from "api/models/auctionSummary";
import { GetAuctionsQuery } from "api/requests/getAuctionsQuery";
import { useAuctionsService } from "api/services/auctionsService";
import AuctionsListView from "components/auction/AuctionsListView";
import AuctionsFilters from "components/filter/AuctionsFilters";
import Spinner from "components/spinner";
import { Pagination } from "flowbite-react";
import useAuctionsSearchParams from "hooks/auctionsSearchParams";
import { useEffect, useState } from "react";
import { useAppSelector } from "store";

const AuctionsDashboardPage = () => {
  const { getAuctions } = useAuctionsService();
  const [isLoadingAuctions, setIsLoadingAuctions] = useState(true);
  const categories = useAppSelector((state) => state.staticData.categories);
  const countries = useAppSelector((state) => state.staticData.countries);
  const [auctions, setAuctions] = useState<AuctionSummary[]>(null);
  const [totalAuctions, setTotalAuctions] = useState<number>(0);
  const [totalPages, setTotalPages] = useState(1);
  const { params: auctionsSearchParams, setPage, queryString } = useAuctionsSearchParams();
  const { category: selectedCategories, country: selectedCountries, page } = auctionsSearchParams;

  const fetchAuctions = async () => {
    setIsLoadingAuctions(true);
    const auctionsQuery: GetAuctionsQuery = {
      categoryIds: selectedCategories,
      countryCodes: selectedCountries,
      statuses: ["Active"],
      pageIndex: page ? page - 1 : 0,
      pageSize: 10,
      sortBy: undefined,
      sortDescending: false
    };

    const auctionsData = (await getAuctions(auctionsQuery)).data;
    setAuctions(auctionsData.items);
    setTotalAuctions(auctionsData.totalCount);
    setTotalPages(Math.trunc((auctionsData.totalCount + auctionsQuery.pageSize - 1) / auctionsQuery.pageSize));
    setIsLoadingAuctions(false);
  }

  useEffect(() => {

    fetchAuctions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString]);

  return (
    <div className="mx-2 h-full gap-4">

      <div className="bg-lightPrimary w-full min-w-0 flex-col mx-auto space-y-2 md:space-y-4">
        <article className="flex items-center gap-2 mt-2 ml-2 font-semibold text-xl md:text-2xl xl:text-3xl">
          <h3>Licitații active</h3>
          <h3 className="text-gray-500">{totalAuctions}</h3>
        </article>

        <AuctionsFilters withCountries={countries} withCategories={categories} />

        {isLoadingAuctions
          ? <Spinner />
          : <>
            {auctions && <AuctionsListView auctionSummaries={auctions} />}

            {auctions && totalPages > 1 && <Pagination
              currentPage={page}
              totalPages={totalPages}
              onPageChange={(page) => setPage(page)}
              showIcons
              nextLabel="Înainte"
              previousLabel="Înapoi"
              className="flex justify-center md:float-right" />}
          </>}
      </div>
    </div>
  );
};

export default AuctionsDashboardPage;
