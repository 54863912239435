import { VerifyEmailCommand } from "api/requests/verifyEmailCommand";
import { useUserService } from "api/services/usersService";
import { useAuth } from "api/useAuth";
import Spinner from "components/spinner";
import { Button } from "flowbite-react";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useAppDispatch } from "store";
import { setUser } from "store/features/userSlice";

const VerifyEmailPage = () => {
  const { isAuthenticated } = useAuth();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const { getMyUser, verifyEmail } = useUserService();

  useEffect(() => {
    const token = searchParams.get("token");
    const verifyUserEmail = async () => {
      const command: VerifyEmailCommand = {
        verificationCode: token
      };
      await verifyEmail(command);

      if (isAuthenticated) {
        const myUser = (await (getMyUser())).data;
        dispatch(setUser(myUser));
      }

      setIsLoading(false);
    }

    verifyUserEmail();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="h-full w-full flex px-2 md:px-0 mb-2 lg:mb-10 justify-start md:justify-center items-start md:items-center">
      <div className="w-full md:w-[50%]">
        <span className="font-bold text-navy-700 text-lg md:text-3xl">Email-ul tău a fost confirmat</span>
        <p className="mb-9 mt-4 text-sm md:text-base text-gray-600">
          Îți multumim ca ți-ai confirmat adresa de email, de acum putem ști cu siguranță că orice informație va ajunge la tine cu succes.
        </p>
        <p className="mb-9 text-sm md:text-base text-gray-600">
          Următorul pas pentru a putea folosi complet platforma este să ne oferi câteva date despre societatea ta. Acestea ne vor ajuta să urmăm procesul legal pentru a putea participa la licitații.
        </p>
        <Link to="/onboarding" className="flex justify-center items-center">
          <Button
            className="w-[75%] md:w-[50%]"
            color="brand"
            size="xl">
            Completează formularul
          </Button>
        </Link>
        <p className="mt-9 text-sm md:text-base text-gray-600">
          Dacă nu ești hotărât să licitezi încă și vrei doar să să vezi loturile publicate, poți <a className="decoration-600 dark:decoration-500 inline font-medium text-brand-600 underline decoration-solid underline-offset-2 hover:no-underline dark:text-brand-500"
            href="/">sări peste</a> acest pas.
        </p>
      </div>
    </div>
  );

};

export default VerifyEmailPage;