export class CountryCodes {
  static readonly Romania = "RO";
}

class BaseQueryParams {
  static readonly Page = "page";
  static readonly SortBy = "sortBy";
  static readonly SortDirection = "sortDirection";
}

export type SortDirection = "asc" | "desc";

export class LotsQueryParams extends BaseQueryParams {
  static readonly Search = "search";
  static readonly Category = "category";
  static readonly Country = "country";
  static readonly Status = "status";
  static readonly IsReserved = "isReserved";
};

export class AuctionsQueryParams extends BaseQueryParams {
  static readonly Category = "category";
  static readonly Country = "country";
};