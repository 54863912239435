import { BiddedLotsOverview } from "api/models/biddedLotsOverview";
import { useUserService } from "api/services/usersService";
import Spinner from "components/spinner";
import { Badge, Card } from "flowbite-react";
import { useEffect, useState } from "react";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { RiAuctionFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

type InvoicesOverview = {
  totalInvoicesCount: number;
  awaitingPaymentInvoicesCount: number;
  paidInvoicesCount: number;
}

export default function UserAccount() {
  const { getBiddedLotsOverview } = useUserService();
  const navigate = useNavigate();
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [biddedLotsOverview, setBiddedLotsOverview] = useState<BiddedLotsOverview>(null);

  const invoicesOverview: InvoicesOverview = {
    totalInvoicesCount: 6,
    awaitingPaymentInvoicesCount: 2,
    paidInvoicesCount: 4
  }; // TO-DO - replace this hardcoded value with server endpoint result

  const fetchData = async () => {
    setIsLoadingData(true);

    setBiddedLotsOverview((await getBiddedLotsOverview()).data);

    setIsLoadingData(false);
  };

  useEffect(() => {

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoadingData) {
    return <Spinner />
  }

  return (
    <div className="mx-2 md:mx-10 grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-2">
      <Card className="max-w-sm">
        <div className="space-y-4">
          <div className="h-12 flex justify-between items-center p-2">
            <div className="flex items-center gap-2">
              <Badge color="brand" size="sm" icon={RiAuctionFill} />
              <p className="font-bold">Licitări</p>
            </div>
            <p className="text-lg font-semibold">{biddedLotsOverview.totalBiddedLots}</p>
          </div>
          <hr className="h-px bg-brand-200 border-0"></hr>
          <div className="space-y-3">
            <div className="flex justify-between items-center p-2 rounded-lg cursor-pointer hover:bg-gray-100" onClick={() => navigate(`/my-account/bids/Active`)}>
              <p>Licitări pe loturi active</p>
              <Badge color="brand" size="sm" className="shrink-0">{biddedLotsOverview.totalActiveLots}</Badge>
            </div>
            <div className="flex justify-between items-center p-2 rounded-lg cursor-pointer hover:bg-gray-100" onClick={() => navigate(`/my-account/bids/Won`)}>
              <p>Licitări pe loturi câștigate</p>
              <Badge color="brand" size="sm" className="shrink-0">{biddedLotsOverview.totalWonLots}</Badge>
            </div>
            <div className="flex justify-between items-center p-2 rounded-lg cursor-pointer hover:bg-gray-100" onClick={() => navigate(`/my-account/bids/Lost`)}>
              <p>Licitări pe loturi pierdute</p>
              <Badge color="brand" size="sm" className="shrink-0">{biddedLotsOverview.totalLostLots}</Badge>
            </div>
          </div>
        </div>
      </Card>

      <Card className="max-w-sm">
        <div className="h-full space-y-4">
          <div className="h-12 flex justify-between items-center p-2">
            <div className="flex items-center gap-2">
              <Badge color="brand" size="sm" icon={LiaFileInvoiceDollarSolid} />
              <p className="font-bold">Facturi</p>
            </div>
            <p className="text-lg font-semibold">{invoicesOverview.totalInvoicesCount}</p>
          </div>
          <hr className="h-px bg-brand-200 border-0"></hr>
          <div className="space-y-3">
            <div className="flex justify-between items-center p-2 rounded-lg cursor-pointer hover:bg-gray-100" onClick={() => navigate(`/auctions/`)}>
              <p>În așteptarea plății</p>
              <Badge color="brand" size="sm" className="shrink-0">{invoicesOverview.awaitingPaymentInvoicesCount}</Badge>
            </div>
            <div className="flex justify-between items-center p-2 rounded-lg cursor-pointer hover:bg-gray-100">
              <p>Plătite</p>
              <Badge color="brand" size="sm" className="shrink-0">{invoicesOverview.paidInvoicesCount}</Badge>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}