/* eslint-disable react/style-prop-object */
import { UserLotStatus } from 'api/models/userLotStatus';
import { Dropdown, Tabs, TabsRef } from 'flowbite-react';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BiddedLotsListView from './BiddedLotsListView';

type BiddedLotsStatusMap = Partial<Record<UserLotStatus, string>>;

export default function UserBiddedLotsLayout() {
  const { status } = useParams();
  const navigate = useNavigate();
  const tabsRef = useRef<TabsRef>(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const biddedLotsStatusMap: BiddedLotsStatusMap =
  {
    "Active": "În desfășurare",
    "Won": "Câștigate",
    "Lost": "Pierdute",
    "Reserved": "Rezervate",
    "WaitingPayment": "Așteaptă plata",
    "Paid": "Plătite"
  };
  const biddedLotsStatuses = Object.keys(biddedLotsStatusMap) as UserLotStatus[];

  const [selectedStatus, setSelectedStatus] = useState<UserLotStatus>(status as UserLotStatus);

  useEffect(() => {

    const matchedStatusIndex = biddedLotsStatuses.findIndex(s => s === status);
    if (matchedStatusIndex === -1) {
      navigate(`/error/not-found`);
      return;
    };

    setSelectedStatus(biddedLotsStatuses[matchedStatusIndex]);
    tabsRef.current?.setActiveTab(matchedStatusIndex);

    const handleResize = (() => window.innerWidth < 1200 ? setIsSmallScreen(true) : setIsSmallScreen(false));
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectBidsOverview = (selectedStatus: UserLotStatus) => {
    setSelectedStatus(selectedStatus);
    navigate(`/my-account/bids/${selectedStatus}`)
  }

  const onTabOverviewChanged = (tabIndex: number) => {
    const selectedStatus = biddedLotsStatuses[tabIndex];
    setSelectedStatus(selectedStatus);
    navigate(`/my-account/bids/${selectedStatus}`);
  }

  const tabsLayout = () =>
    <Tabs aria-label="Licitări" style="underline" ref={tabsRef} onActiveTabChange={(tab) => onTabOverviewChanged(tab)}>
      {biddedLotsStatuses.map((status, index) =>
        <Tabs.Item key={index} active title={biddedLotsStatusMap[status]}>
        </Tabs.Item>
      )}
    </Tabs>;

  const dropdownLayout = () =>
    <Dropdown
      theme={{ floating: { target: "w-full" } }}
      color="brand"
      label={biddedLotsStatusMap[selectedStatus]}>
      {
        biddedLotsStatuses.map((status, index) => (
          <div key={index}>
            <Dropdown.Item
              onClick={() => onSelectBidsOverview(status)}>
              {biddedLotsStatusMap[status]}</Dropdown.Item>
            {index < biddedLotsStatuses.length - 1 && <Dropdown.Divider />}
          </div>
        ))}
    </Dropdown>;

  return (
    <div className="mx-2 md:mx-10">
      <h4 className="mb-2 text-xl md:text-2xl font-bold text-navy-700">Licitări</h4>
      {isSmallScreen ? dropdownLayout() : tabsLayout()}
      <div className="py-4">
        <BiddedLotsListView status={selectedStatus} />
      </div>
    </div>
  );
}
