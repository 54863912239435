import useLotsHub from "api/hubs/lotsHub";
import { File } from "api/models/file";
import { Lot } from "api/models/lot";
import { LotCondition } from "api/models/lotCondition";
import { User } from "api/models/user";
import { useLotsService } from "api/services/lotsService";
import { useAuth } from "api/useAuth";
import LotBidModal from "components/lot/LotBidModal";
import LotEndDate from "components/lot/LotEndDate";
import LotPriceBreakdown from "components/lot/LotPriceBreakdown";
import LotReservedPrice from "components/lot/LotReservedPrice";
import Spinner from "components/spinner";
import { Alert, Button, Popover, TextInput } from "flowbite-react";
import { neatDate, neatDateWithWeekday } from "formatters/date";
import { memo, useEffect, useRef, useState } from "react";
import { HiInformationCircle, HiOutlineClock } from "react-icons/hi";
import { HiOutlineDocumentArrowDown } from "react-icons/hi2";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { Link, useParams } from "react-router-dom";
import { useAppSelector } from "store";
import { useSlug } from "utils/slugHelper";

type State = {
  lot: Lot;
  bidInformation: {
    nextPrice: number;
    error?: string;
    isInitialBidSurpassed: boolean;
  };
}
type LotConditionMap = Partial<Record<LotCondition, string>>;
const conditionMap: LotConditionMap = { "New": "Nou", "Used": "Folosit" }

const LotDetailsPage = () => {
  const { isAuthenticated, login } = useAuth();
  const user = useAppSelector((state) => state.currentUser);
  const { getLotById } = useLotsService();
  const { lotSlug } = useParams();
  const { slugify, getSlugHash } = useSlug();
  const lotId: number = getSlugHash(lotSlug);
  const [lotInfo, setLotInfo] = useState<State>({
    lot: null,
    bidInformation: {
      nextPrice: 0,
      isInitialBidSurpassed: false
    }
  });
  const [isLoadingLot, setIsLoadingLot] = useState(true);
  const [galleryImagesAndVideos, setGalleryImagesAndVideos] = useState<ReactImageGalleryItem[]>(null);
  const imageGalleryRef = useRef(null);
  const [userAcceptedTermsAndConditions, setUserAcceptedTermsAndConditions] = useState<boolean>(false);
  const [openBidModal, setOpenBidModal] = useState(false);

  useLotsHub([lotId],
    (notification) => {
      setLotInfo((prev) => {
        if (prev.lot.id === notification.lotId) {
          return {
            lot: {
              ...prev.lot,
              priceDetails: {
                ...prev.lot.priceDetails,
                currentPrice: notification.amount
              },
              latestBid: {
                ...prev.lot.latestBid,
                isCurrentUserBid: isAuthenticated && notification.userId === user.id
              },
              bidsCount: prev.lot.bidsCount + 1
            },
            bidInformation: {
              ...prev.bidInformation,
              nextPrice: Math.max(prev.bidInformation.nextPrice, getMinimumBidAmount(prev.lot.bidsCount + 1, notification.amount, prev.lot.priceDetails.incrementStep)),
              isInitialBidSurpassed: isAuthenticated && notification.userId !== user.id
            }
          }
        }
        return prev;
      });
    },
    (notification) => {
      setLotInfo((prev) => {
        if (prev.lot.id === notification.lotId) {
          return {
            ...prev,
            lot: {
              ...prev.lot,
              endsAtUtc: notification.endsAtUtc
            }
          }
        }
        return prev;
      });
    },
    (notification) => {
      setLotInfo((prev) => {
        if (prev.lot.id === notification.lotId) {
          return {
            ...prev,
            lot: {
              ...prev.lot,
              status: notification.status
            }
          }
        }
        return prev;
      });
    }
  );

  const fetchLot = async () => {
    setIsLoadingLot(true);
    const fetchedLot = (await getLotById(lotId)).data;
    setLotInfo({
      lot: fetchedLot,
      bidInformation: {
        nextPrice: getMinimumBidAmount(fetchedLot.bidsCount, fetchedLot.priceDetails.currentPrice, fetchedLot.priceDetails.incrementStep),
        isInitialBidSurpassed: false
      }
    });
    setUserAcceptedTermsAndConditions(fetchedLot.auction.currentUserAuctionTermsSignature !== null)
    const gallery = setImages(fetchedLot).concat(setVideos(fetchedLot));
    setGalleryImagesAndVideos(gallery);
    setIsLoadingLot(false);
  }

  useEffect(() => {
    fetchLot();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lotId]);

  const GalleryVideo = memo(function Video({ url }: { url: string }) {
    return (
      <video
        className="image-gallery-image"
        width="750"
        height="500"
        controls
        controlsList="nodownload nofullscreen noremoteplayback">
        <source src={url} />
      </video>
    );
  });

  const renderThumbnail = (item: ReactImageGalleryItem) => {
    return (
      <span className="image-gallery-thumbnail-inner">
        <video
          className="image-gallery-thumbnail-image"
          width="88"
          height="72"
          preload="metadata"
          controlsList="nodownload">
          <source src={item.original} />
        </video>
      </span>
    );
  }

  const onClickImage = () => {
    imageGalleryRef.current.toggleFullScreen();
  }

  const setImages = (lot: Lot): ReactImageGalleryItem[] => {
    return lot.images.map((image: File): ReactImageGalleryItem => ({
      original: image.url,
      originalClass: 'h-[300px] lg:h-[500px] w-screen lg:w-[750px] ',
      fullscreen: image.url,
      thumbnail: image.url,
      description: image.description,
      thumbnailClass: 'h-20 w-24',
      originalAlt: image.displayFileName,
      thumbnailAlt: image.displayFileName,
    }));
  }

  const setVideos = (lot: Lot): ReactImageGalleryItem[] => {
    return lot.videos.map((video: File): ReactImageGalleryItem => ({
      original: video.url,
      originalClass: 'h-[300px] lg:h-[500px] w-screen lg:w-[750px] ',
      fullscreen: video.url,
      thumbnail: video.url,
      thumbnailClass: 'h-20 w-24',
      description: video.description,
      originalAlt: video.displayFileName,
      thumbnailAlt: video.displayFileName,
      renderThumbInner: renderThumbnail,
      renderItem: () => <GalleryVideo url={video.url}></GalleryVideo>
    }));
  }

  const redirectToLogin = () => login();

  const getMinimumBidAmount = (bidCount: number, currentPrice: number, incrementStep: number): number => {
    if (bidCount === 0) {
      return currentPrice;
    }
    return currentPrice + incrementStep;
  }

  const onChangeBidAmount = (lot: Lot, bidAmount: number): void => {
    setLotInfo((prev) => {
      const nextMinimumBid = getMinimumBidAmount(prev.lot.bidsCount, prev.lot.priceDetails.currentPrice, prev.lot.priceDetails.incrementStep);

      return {
        ...prev,
        bidInformation: {
          ...prev.bidInformation,
          nextPrice: bidAmount,
          error: bidAmount < nextMinimumBid ? `Oferta minimă este ${nextMinimumBid} ${lot.auction.currency}` : "",
          isInitialBidSurpassed: false
        }
      };
    })
  };

  const getLotPriceAndStatus = (lot: Lot): JSX.Element => {
    return (<>
      {lot.priceDetails.currentPrice &&
        <div className="flex justify-between items-center font-semibold text-lg lg:text-xl gap-3">
          <div className="space-y-2">
            <p>{lot.bidsCount > 0 ? 'Ultima ofertă' : 'Preț pornire'} {lot.bidsCount > 0 && <span className="font-semibold text-gray-700 text-base">({lot.bidsCount} {lot.bidsCount > 1 ? 'licitări' : 'licitare'})</span>}</p>
            <p className="font-bold text-xl lg:text-2xl">{lot.priceDetails.currentPrice} {lot.auction.currency}</p>
          </div>
          <div className="space-y-2">
            <p className="text-end">Status</p>
            <LotReservedPrice showLabel isReserved={lot.isReserved} labelClass="text-base lg:text-lg font-medium" iconClass="text-xl" />
          </div>
        </div >}
    </>
    );
  }

  const getInvalidAccountAlert = (user: User): JSX.Element => {
    let content;
    switch (user.status) {
      case 'Created': {
        content = "E nevoie să confirmi contul pentru a putea licita. Verifică-ți emailul!";
        break;
      }
      case "Confirmed": {
        content = <Link to="/onboarding">Vrei să licitezi? Imediat, mai întâi avem nevoie de câteva date de la tine</Link>;
        break;
      }
      case "ValidationInProgress": {
        content = 'Încă puțin și vei putea licita! Datele tale sunt analizate, revenim către tine pe email în cel mai scurt timp!';
        break;
      }
      case "Rejected": {
        content = <>Ne pare rău, nu poți licita. Datele tale nu au trecut de analiză. Contactează-ne la <a className="decoration-600 inline font-medium text-brand-600 underline decoration-solid underline-offset-2 hover:no-underline " href="mailto:contact@licitas.ro">contact@licitas.ro.</a> pentru a afla mai multe detalii</>
        break;
      }
      default: break;
    }

    return (
      <Alert color="blue" icon={HiInformationCircle}>
        <span>
          {content}
        </span>
      </Alert>)
  }

  const getActiveLotBiddingInfo = (lot: Lot): JSX.Element => {
    return (<>
      <div className="flex flex-col w-full justify-between gap-2 lg:gap-3 mx-4 my-6">
        {getLotPriceAndStatus(lot)}
        {!isAuthenticated &&
          <Alert color="blue" className="cursor-pointer" icon={HiInformationCircle}
            onClick={redirectToLogin}>
            <span>Autentifică-te pentru a putea licita!</span>
          </Alert>}
        {(isAuthenticated && user && user.status !== 'Approved') && getInvalidAccountAlert(user)}
        {(isAuthenticated && user && user.status === 'Approved') &&
          <>
            <div className="space-y-1">
              {(lot.latestBid && lot.latestBid.isCurrentUserBid) && <p className="text-cyan-500">Ultima ofertă este trimisă de tine!</p>}
              {lotInfo.bidInformation.isInitialBidSurpassed && <p className="text-red-500">S-a înregistrat o nouă licitare. Oferta minimă a fost actualizată!</p>}
              <div className="flex flex-col md:flex-row w-full items-start justify-stretch gap-2 lg:gap-4">
                <div className="w-full">
                  <TextInput
                    id="bidAmount"
                    type="number"
                    sizing="lg"
                    placeholder={lotInfo.bidInformation.nextPrice.toString()}
                    addon={lot.auction.currency}
                    color={lotInfo.bidInformation.error ? "failure" : undefined}
                    onChange={(e) => onChangeBidAmount(lot, +e.target.value)}
                    value={lotInfo.bidInformation.nextPrice.toString()}
                    helperText={lotInfo.bidInformation.error}
                  />
                </div>
                <Button className="grow h-[58px] w-full" size="lg" color="brand" onClick={() => setOpenBidModal(true)} disabled={!!lotInfo.bidInformation.error}>Licitează</Button>
              </div>
            </div>
            <LotPriceBreakdown lotPriceDetails={lot.priceDetails} currency={lot.auction.currency} bidAmount={lotInfo.bidInformation.nextPrice} />
          </>}
      </div>
      {openBidModal && isAuthenticated &&
        <LotBidModal
          show={openBidModal}
          onCloseModal={() => setOpenBidModal(false)}
          lot={lot}
          bidAmount={lotInfo.bidInformation.nextPrice}
          userAcceptedTermsAndConditions={userAcceptedTermsAndConditions}
          onAcceptedTermsAndConditions={() => setUserAcceptedTermsAndConditions(true)} />}
    </>)
  };

  const getInactiveLotBiddingInfo = (lot: Lot): JSX.Element => {
    const additionalContent = <div className="mb-4 mt-2 text-sm text-brand-700">
      Ai întrebări sau ești interesat de acest articol? Contactează-ne la <a className="decoration-600 inline font-medium text-brand-600 underline decoration-solid underline-offset-2 hover:no-underline " href="mailto:contact@licitas.ro">contact@licitas.ro.</a>
    </div>
    return (
      <div className="flex flex-col w-full justify-between gap-4 lg:gap-4 mx-4 my-6">
        {getLotPriceAndStatus(lot)}
        <Alert color="success" additionalContent={additionalContent} icon={HiInformationCircle}>
          <span>
            Licitația va începe în curând.
          </span>
        </Alert>
      </div>)
  };

  const getClosedLotBiddingInfo = (lot: Lot): JSX.Element => {
    const additionalContent = <div className="mb-4 mt-2 text-sm text-brand-700">
      Ai întrebări sau ești interesat de acest articol? Contactează-ne la <a className="decoration-600 inline font-medium text-brand-600 underline decoration-solid underline-offset-2 hover:no-underline " href="mailto:contact@licitas.ro">contact@licitas.ro.</a>
    </div>
    return (
      <div className="flex flex-col w-full justify-between gap-2 lg:gap-4 mx-4 my-6">
        <Alert color="failure" additionalContent={additionalContent} icon={HiInformationCircle}>
          <span>
            Această licitație s-a încheiat.
          </span>
        </Alert>
      </div>)
  };

  const getLotSection = (title: string, data: Record<string, string | number | JSX.Element> | string): JSX.Element => {
    return (
      <div className="flex flex-col bg-gray-100 border-x-2 border-b-2 rounded-b-lg">
        <div className="flex w-full h-[45px] bg-brand-secondary-500 text-white font-bold rounded-lg items-center justify-start px-4">{title}</div>
        <div className="flex flex-col gap-3 p-4">
          {typeof data === 'string' ?
            <p>{data}</p>
            : Object.keys(data).map(item =>
              <div className="grid grid-cols-1 lg:grid-cols-3" key={item}>
                <p className="font-semibold">{item}</p>
                <div className="col-span-2">{data[item]}</div>
              </div>)}
        </div>
      </div>
    );
  };

  const documentDownloadTemplate = (file: File) =>
    <Popover key={file.id} content={<p className="w-24 text-sm text-gray-500 px-3 py-2">Descarcă</p>} trigger="hover" placement="bottom">
      <a href={file.url} download className="flex items-center gap-2 cursor-pointer underline underline-offset-2 font-medium text-sm hover:text-brand-500">
        <HiOutlineDocumentArrowDown className="shrink-0" />
        <span className="truncate">{file.displayFileName}</span>
      </a>
    </Popover>;

  if (isLoadingLot) {
    return <Spinner />;
  }

  return (
    <div className="mx-2 mb-10 flex flex-col h-full gap-4">
      <h1 className="font-bold my-2 text-xl md:text-2xl xl:text-3xl truncate">{lotInfo.lot.name}</h1>
      <div className="flex flex-col lg:flex-row items-center lg:items-start gap-4">
        <div className="w-full lg:w-[750px]">
          <ImageGallery
            additionalClass="mx-auto w-full"
            ref={imageGalleryRef}
            items={galleryImagesAndVideos}
            onClick={onClickImage}
            showBullets={true}
            useBrowserFullscreen={false}
            showIndex={true}
            showPlayButton={false} />
        </div>
        <div className="max-h-[500px] w-full">
          <div className="flex h-[60px] lg:h-[80px] bg-brand-secondary-500 text-white rounded-t-lg items-center justify-center">
            <article className="flex items-center text-lg text-white">
              <HiOutlineClock size={28} className="pr-2 text-2xl" />
              {(() => {
                switch (lotInfo.lot.status) {
                  case "Active":
                    return <LotEndDate endDate={lotInfo.lot.endsAtUtc} showLabel dateClass="font-bold" />
                  case "Inactive":
                    return <p>Începe la: <span className="font-bold">{neatDate(lotInfo.lot.startsAtUtc)}</span></p>
                  default:
                    return <p className="font-semibold">Licitația s-a încheiat</p>;
                }
              })()}
            </article>
          </div>
          <div className="flex w-full rounded-b-lg border-x-2 border-b-2 bg-gray-100">
            {(() => {
              switch (lotInfo.lot.status) {
                case "Active":
                  return getActiveLotBiddingInfo(lotInfo.lot);
                case "Inactive":
                  return getInactiveLotBiddingInfo(lotInfo.lot);
                default:
                  return getClosedLotBiddingInfo(lotInfo.lot);
              }
            })()}
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center w-full gap-2 p-4 text-sm text-cyan-700 bg-cyan-100 border-cyan-500  rounded-lg">
        <h5 className="truncate">
          Acest lot face parte din licitația <Link className="font-semibold underline"
            to={`/auctions/${slugify(lotInfo.lot.auction.name, lotInfo.lot.auction.id)}`}>
            <span className="truncate">{lotInfo.lot.auction.name}</span></Link>
        </h5>
      </div>
      <div className="items-start grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-y-6">
        {getLotSection("Despre lot", {
          "Nume": lotInfo.lot.name,
          "Id": lotInfo.lot.id,
          "Vizionare": <span>
            De<span className="font-semibold text-sm"> {neatDateWithWeekday(lotInfo.lot.viewingStartsAtUtc)} </span> <br />
            până<span className="font-semibold text-sm"> {neatDateWithWeekday(lotInfo.lot.viewingEndsAtUtc)}</span>
          </span>,
          "Date ridicare": <span>
            De<span className="font-semibold text-sm"> {neatDateWithWeekday(lotInfo.lot.pickUpStartsAtUtc)} </span> <br />
            până<span className="font-semibold text-sm"> {neatDateWithWeekday(lotInfo.lot.pickUpEndsAtUtc)}</span>
          </span>,
          "Locație": `${lotInfo.lot.country.name}, ${lotInfo.lot.city}`,
        })}
        {getLotSection("Costuri", {
          "Comision platformă": `${lotInfo.lot.priceDetails.platformFeePercentage}%`,
          "TVA": `${lotInfo.lot.priceDetails.vat}%`,
          "Costuri demontare și încărcare": lotInfo.lot.priceDetails.dismantlingCosts ? `${lotInfo.lot.priceDetails.dismantlingCosts} ${lotInfo.lot.auction.currency}` : 'n/a',
          "Costuri transport": <span>La cerere putem oferi servicii de transport. Contactează-ne la < a className="decoration-600 inline font-medium text-brand-600 underline decoration-solid underline-offset-2 hover:no-underline " href="mailto:contact@licitas.ro" > contact@licitas.ro.</a></span>,
          "Condiții de plată": "Plata 100% în avans înainte de ridicare"
        })}
        {getLotSection("Detalii", {
          "Descriere": lotInfo.lot.description,
          "Producător": lotInfo.lot.producer || 'n/a',
          "Stare": conditionMap[lotInfo.lot.condition] || 'n/a',
          "Documente": <span>
            {(lotInfo.lot.documents && lotInfo.lot.documents.length > 0 &&
              lotInfo.lot.documents.map((doc) => doc && doc.url && documentDownloadTemplate(doc))) || 'n/a'}
          </span>,
          "Categorie": <Link to={`/lots/${slugify(lotInfo.lot.category.name, lotInfo.lot.category.id)}`} className="italic underline underline-offset-2 hover:text-brand-500">{lotInfo.lot.category.name}</Link>
        })}
        {getLotSection("Specificații tehnice", {
          "An fabricație": lotInfo.lot.yearOfManufacture || 'n/a',
          "Greutate": lotInfo.lot.weightKg ? `${lotInfo.lot.weightKg}kg` : 'n/a',
          "Lungime totală": lotInfo.lot.dimensionLengthMm ? `${lotInfo.lot.dimensionLengthMm}mm` : 'n/a',
          "Lățime totală": lotInfo.lot.dimensionWidthMm ? `${lotInfo.lot.dimensionWidthMm}mm` : 'n/a',
          "Înălțime totală": lotInfo.lot.dimensionHeightMm ? `${lotInfo.lot.dimensionHeightMm}mm` : 'n/a'
        })}
      </div>
    </div>
  );
};
export default LotDetailsPage;
